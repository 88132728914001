<template>
  <header>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark ">
    <router-link class="navbar-brand" to="/home">AgeVSCodeUpdater</router-link>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
      <div class="navbar-nav text-rigth flex-grow-0">
        <template v-if="$store.getters.loggedIn">
          <router-link class="nav-item nav-link" to="/extension/create">NEW UPDATE</router-link>
          <router-link class="nav-item nav-link" to="/user/list">USERS</router-link>
          <router-link class="nav-item nav-link" to="/extension/list">UPDATES LIST</router-link>
          <router-link class="nav-item nav-link" @click.native="logoutSubmit" to="/home">LOGOUT</router-link>
        </template>
        <template v-else>
          <router-link class="nav-item nav-link" to="/home">HOME</router-link>
          <router-link class="nav-item nav-link" to="/login">LOGIN</router-link>
        </template>
      </div>
    </div>

  </nav>
  </header>
</template>

<script>

import { mapActions } from 'vuex'

export default {
  name: 'Navbar',
  data () {
    return {
      isAuthenticated: true
    }
  },
  methods: {
    ...mapActions([
      'doLogout'
    ]),
    logoutSubmit () {
      this.doLogout()
    }
  }
}
</script>

<style scoped>

</style>
