<template>
  <div class="container">
    <div class="row justify-content-center">
      <h3>Updates List</h3>
    </div>
    <br>

    <div class="row justify-content-center">
      <div class="col-sm-6 col-sm-offset-3 col-md-6 col-md-offset-3 col-lg-8 col-lg-offset-2 col-xl-8 col-xl-offset-2">
        <table class="table table-bordered text-center">
          <thead>
            <tr>
              <th class="text-center">Version</th>
              <th class="text-center">Release Date</th>
              <th class="text-center">Reported Bugs</th>
              <th class="text-center"></th>
            </tr>
          </thead>
          <tbody>

              <tr v-for="update in updates" :key="update.id">
                <td>{{update.version}}</td>
                <td>{{update.createdAt}}</td>
                <td contenteditable="true" @blur="updateBugReport(update, $event)" v-html="update.bugReport"></td>
                <td>
                  <button class="btn btn-primary" v-if="update.filename" @click="downloadExtensionVersion(update.version)">Download {{update.version}}</button>
                </td>
              </tr>

          </tbody>
        </table>
          <button class="btn btn-secondary" @click="downloadExtension" >Download last version!</button>
      </div>
    </div>

  </div>
</template>

<script>

import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ListUpdates',
  data () {
    return {
      updates: []
    }
  },
  methods: {
    updateBugReport (update, event) {
      axios.put(`${process.env.VUE_APP_BACKEND_ENDPOINT}/version`, {
        id: update.id,
        bugReport: event.target.innerHTML
      },
      {
        headers: {
          'x-access-token': this.getToken()
        }
      })
        .then((response) => {
          update.bugReport = event.target.innerHTML
        })
        .catch((error) => {
          console.log(error.data)
        })
    },
    downloadExtension () {
      axios.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/downloadExtension?password=fodeivos20x`, {
        responseType: 'blob',
        headers: {
          'x-access-token': this.getToken()
        }
      })
        .then((response) => {
          let fileURL = window.URL.createObjectURL(new Blob([response.data]))
          let fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'ageVSCodeUpdate.vsix')
          document.body.appendChild(fileLink)

          fileLink.click()
          fileLink.parentNode.removeChild(fileLink)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    downloadExtensionVersion (version) {
      axios.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/downloadExtensionVersion?password=fodeivos20x&version=${version}`, {
        responseType: 'blob',
        headers: {
          'x-access-token': this.getToken()
        }
      })
        .then((response) => {
          let fileURL = window.URL.createObjectURL(new Blob([response.data]))
          let fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'ageVSCodeUpdate.vsix')
          document.body.appendChild(fileLink)

          fileLink.click()
          fileLink.parentNode.removeChild(fileLink)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    ...mapActions([
      'doLogout'
    ]),
    ...mapGetters([
      'getToken'
    ])
  },
  mounted () {
    axios.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/extension/list`, {
      headers: {
        'x-access-token': this.getToken()
      }
    })
      .then((response) => {
        this.updates = response.data
      })
      .catch((error) => {
        if (error.response.data.status === 401) {
          this.doLogout()
        }
        console.log(error.response.data)
      })
  }
}
</script>

<style scoped>

</style>
