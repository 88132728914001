<template>
  <div class="container-fluid bg-3 text-center">
    <h3>Welcome to age VSCode Updater</h3><br>

    <div class="row">
      <template v-if="loginSuccessful">
        <div class="col-sm-4">
          <h4>New Update</h4>
          <p>Upload a new vsix file update</p>
        </div>
        <div class="col-sm-4">
          <h4>Users</h4>
          <p>List Users, their versions, and manage permissions</p>
        </div>
        <div class="col-sm-4">
          <h4>List</h4>
          <p>List and download current updates</p>
        </div>
      </template>
      <template v-else>
        <div class="container-fluid bg-3 text-center">
          <p>Please login to proceed</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'Home',
  data () {
    return {
      isAuthenticated: true
    }
  },
  computed: {
    ...mapState([
      'loginSuccessful'
    ])
  }

}
</script>

<style scoped>

</style>
