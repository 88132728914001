<template>
  <div class="container text-center">
    <h3>Upload Extension Update</h3><br>

    <div class="row justify-content-center">
      <div class="col-sm-6 col-sm-offset-3 col-4 col-offset-4">
            <label for="fileInput" class="control-label" >File
              <input type="file" id="fileInput" ref="file" name="extension" required @change="handleFileUpload()" />
            </label>
            <label for="versionInput" class="control-label" >Version
              <input type="text" class="form-control" id="versionInput" name="version" v-model="version" required>
            </label>
      </div>
    </div>
    <button type="submit" class="btn btn-warning btn-lg" @click="submitUpdate">Upload</button>
    <div class="justify-content-center row">
      <div class="col-sm-4 col-sm-offset-4">
        <br>
        <div  class="alert alert-danger alert-dismissible fade show" role="alert"
              v-if="uploadError===1"
        >
          Upload Error
          <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="dismissError">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div  class="alert alert-success" role="alert"
              v-if="uploadError===0">
          Upload Successful
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'NewUpdate',
  data () {
    return {
      file: '',
      version: '',
      uploadError: null
    }
  },
  methods: {
    submitUpdate () {
      let formData = new FormData()

      formData.append('extension', this.file)
      formData.append('version', this.version)

      axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/extension/store`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-access-token': this.getToken()
          }
        })
        .then((response) => {
          this.uploadError = 0
          this.version = ''
          const fileRef = this.$refs.file
          fileRef.type = 'text'
          fileRef.type = 'file'
        })
        .catch((error) => {
          this.uploadError = 1
          if (error.data.status === 401) {
            this.doLogout()
          }
          console.log(error.data)
        })
    },
    handleFileUpload () {
      this.uploadError = null
      this.file = this.$refs.file.files[0]
    },
    dismissError () {
      this.uploadError = null
    },
    ...mapActions([
      'doLogout'
    ]),
    ...mapGetters([
      'getToken'
    ])
  }
}
</script>

<style scoped>

</style>
