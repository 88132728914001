import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import { store } from '@/store/store'
import Home from '@/components/Home'
import Login from '@/components/Login'
import ListUsers from '@/components/ListUsers'
import ListUpdates from '../components/ListUpdates'
import NewUpdate from '../components/NewUpdate'

//Vue.use(Router)

let entryUrl = null

const guard = async (to, from, next) => {
  if (store.getters.loggedIn) {
    if (entryUrl) {
      const url = entryUrl
      entryUrl = null
      return next(url)
    } else {
      return next()
    }
  }

  if (store.getters.loggedIn) {
    next()
  } else {
    entryUrl = to.path
    next('/login')
  }
}
const router = createRouter({
  mode: 'history',
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      name: 'LandPage',
      component: Home
    },
    {
      path: '/home',
      name: 'Home',
      component: Home
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/user/list',
      beforeEnter: guard,
      name: 'UsersList',
      component: ListUsers
    },
    {
      path: '/extension/list',
      beforeEnter: guard,
      name: 'ExtensionsList',
      component: ListUpdates
    },
    {
      path: '/extension/create',
      beforeEnter: guard,
      name: 'ExtensionsCreate',
      component: NewUpdate
    }
  ]
})

export default router
