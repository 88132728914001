<template>

  <div class="container-fluid bg-3 text-center">
    <h3>Login</h3><br>
    <div class="row justify-content-center">
      <div class="col-sm-4 col-sm-offset-4
                  col-md-4 col-md-offset-4
                  col-lg-4 col-lg-offset-4
                  col-xl-2 col-xl-offset-5">
        <div v-if="loggingIn" class="container-loading">
          <img :src="require('@/assets/loading.gif')" alt="Loading Icon">
        </div>
        <div  class="alert alert-danger alert-dismissible fade show" role="alert"
              v-if="loginError"
              >
          {{ loginError }}
          <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="dismissError">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div  class="alert alert-success" role="alert"
              v-if="loginSuccessful">
          Login Successful
        </div>
        <form v-if="!loggingIn && !loginSuccessful" @submit.prevent="loginSubmit">
          <div class="form-group">
            <label>Email</label>
            <input type="email" class="form-control" name="email" autocomplete="email" v-model="email">
          </div>
          <div class="form-group">
            <label>Password</label>
            <input type="password" class="form-control" name="password" autocomplete="password" v-model="password">
          </div>

          <button type="submit" class="btn btn-warning btn-lg">Login</button>
        </form>

      </div>
    </div>

  </div>

</template>

<script>

import { mapState, mapActions } from 'vuex'

export default {
  name: 'Login',
  data () {
    return {
      email: '',
      password: ''
    }
  },
  computed: {
    ...mapState([
      'loggingIn',
      'loginError',
      'loginSuccessful'
    ])
  },
  methods: {
    ...mapActions([
      'doLogin',
      'doDismissError'
    ]),
    loginSubmit () {
      this.doLogin({
        email: this.email,
        password: this.password
      })
    },
    dismissError () {
      this.doDismissError()
    }
  }

}
</script>

<style scoped>

</style>
