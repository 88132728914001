<template>
  <div id="footer">
    <p class="text-center text-muted">© Copyright {{year}} LudusCristaltec</p>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>

<style scoped>
#footer {
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
}
</style>
