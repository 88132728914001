<template>
  <div class="container">
    <div class="row justify-content-center">
      <h3>Users List</h3>
    </div>
    <br>
    <div class="row justify-content-center">
      <div class="col-sm-6 col-sm-offset-3 col-md-6 col-md-offset-3 col-lg-8 col-lg-offset-2 col-xl-8 col-xl-offset-2">
        <table class="table table-bordered text-center">
          <thead>
            <tr>
              <th class="text-center">Name</th>
              <th class="text-center">Email</th>
              <th class="text-center">User Version</th>
              <th class="text-center">UpdateScheduled</th>
              <th class="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>

          <tr v-for="user in users" :style="[user.isActive? '' : {'color':'#FF0000'} ]" :key="user.id" >
          <td>{{user.name}}</td>
          <td>{{user.email}}</td>
          <td>{{user.currentVersion}}</td>
          <td>{{user.updateScheduled===true? 'yes': 'none'}}</td>
          <td>
            <div class="btn-group dropright justify-content-center">
              <button type="button" class="btn btn-outline-secondary dropdown-toggle" :id="user.id" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Action</button>
              <div class="dropdown-menu" :aria-labelledby="user.id">
                <button class="dropdown-item" type="button" @click="toggleUpdateSchedule(user)">{{user.updateScheduled===false? 'SCHEDULE' : 'CANCEL'}}</button>
                <button class="dropdown-item" type="button" @click="toggleActiveStatus(user)">{{user.isActive? 'BLOCK' : 'ACTIVATE'}}</button>
              </div>
            </div>
          </td>
          </tr>


          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script>

import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'ListUsers',
  data () {
    return {
      users: []
    }
  },
  methods: {
    toggleUpdateSchedule (user) {
      axios.put(`${process.env.VUE_APP_BACKEND_ENDPOINT}/user/update`, {
        email: user.email,
        updateScheduled: !user.updateScheduled
      },
      {
        headers: {
          'x-access-token': this.getToken()
        }
      })
        .then((response) => {
          user.updateScheduled = !user.updateScheduled
        })
        .catch((error) => {
          console.log(error.data.error)
        })
    },
    toggleActiveStatus (user) {
      axios.put(`${process.env.VUE_APP_BACKEND_ENDPOINT}/user/update`, {
        email: user.email,
        isActive: !user.isActive
      },
      {
        headers: {
          'x-access-token': this.getToken()
        }
      })
        .then((response) => {
          user.isActive = !user.isActive
        })
        .catch((error) => {
          console.log(error.data.error)
        })
    },
    ...mapActions([
      'doLogout'
    ]),
    ...mapGetters([
      'getToken'
    ])
  },
  mounted () {
    axios.get(`${process.env.VUE_APP_BACKEND_ENDPOINT}/user/list`, {
      headers: {
        'x-access-token': this.getToken()
      }
    })
      .then((response) => {
        this.users = response.data
      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.doLogout()
        }
        console.log(error.response.data)
      })
  }
}
</script>

<style scoped>

</style>
