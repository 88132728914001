<template>

  <div id="app">
    <vscodeupdater-navbar></vscodeupdater-navbar>
    <br>
    <router-view/>
    <br>
    <vscodeupdater-footer></vscodeupdater-footer>
  </div>

</template>

<script>

import navbar from './components/Navbar.vue'
import home from './components/Home.vue'
import footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    'vscodeupdaterNavbar': navbar,
    //'vscodeupdaterHome': home,
    'vscodeupdaterFooter': footer
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
