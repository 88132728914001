// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { createApp } from 'vue'
import App from './App'
import router from './router/index.js'
import { store } from './store/store'

//Vue.config.productionTip = false


createApp(App)
  .use(router)
  .use(store)
  .mount('#app')

