import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'


export const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    loggingIn: false,
    loginError: null,
    loginSuccessful: false,
    jwtToken: null
  },
  mutations: {
    loginStart (state) {
      state.loggingIn = true
    },
    loginStop: (state, payload) => {
      console.log('stop: ' + payload.errorMessage + ' ' + payload.token)
      state.loggingIn = false
      state.loginError = payload.errorMessage
      state.loginSuccessful = !payload.errorMessage
      state.jwtToken = payload.token
    },
    logout (state) {
      state.loggingIn = false
      state.loginError = null
      state.loginSuccessful = false
      state.jwtToken = null
    },
    clearError (state) {
      state.loginError = null
    },
    stopLoginAnimation (state) {
      state.loggingIn = false
    }
  },
  getters: {
    loggedIn: state => state.loginSuccessful,
    getToken: state => state.jwtToken
  },
  actions: {
    doLogin ({ commit }, loginData) {
      commit('loginStart')

      axios.post(`${process.env.VUE_APP_BACKEND_ENDPOINT}/login`, {
        email: loginData.email || '',
        password: loginData.password || ''
      })
        .then((response) => {
          commit('loginStop', {
            errorMessage: null,
            token: response.data.token
          })
        })
        .catch(error => {
          console.log(error.response)
          commit('loginStop', {
            errorMessage: error.response.data.error,
            token: null
          })
        })
        .finally(() => {
          commit('stopLoginAnimation')
        })
    },
    doLogout ({ commit }) {
      commit('logout')
    },
    doDismissError  ({commit}) {
      commit('clearError')
    }
  }

})
